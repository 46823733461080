import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupText,
  InputGroupAddon,
  InputGroup,
  Row,
  Col,
  Table,
  Container,
} from "reactstrap";
import Header from "../../components/Headers/Header";
import { useToasts } from "react-toast-notifications";
import { postMethod } from "services/httpServices";
import FullPageLoader from "components/FullPageLoader/fullpageloader";
import "react-phone-input-2/lib/style.css";
import moment from "moment";
import NoRecordFound from "components/NoRecordFound/NoRecordFound";
import { getMethod } from "services/httpServices";
import OTPInput from "react-otp-input";

const initialValue = {
  accountID: "",
  amount: "",
  pin: "",
};

const STATUS = [
  { key: "", value: "Select Loan ID", className: "bg-yellow" },
  { key: "00000036315", value: "00000036315", className: "bg-yellow" },
  { key: "00000010033", value: "00000010033", className: "bg-yellow" },
];

const LoanRepayment = (props) => {
  const [values, setValues] = useState(initialValue);
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [showPswd, setShowPswd] = React.useState(false);
  const [statusFilter, setStatusFilter] = React.useState("");
  const [loanAccount, setLoanAccount] = React.useState(null);
  const [success, setSuccess] = useState(false);
  const [counter, setCounter] = useState(5);

  const { addToast } = useToasts();

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const notifySuccess = (text) => {
    if (!text) {
      text = "Save successfully";
    }
    addToast(text, {
      appearance: "success",
      autoDismiss: true,
    });
  };

  // React.useEffect(() => {
  //   if (statusFilter) {
  //     fetchTrans();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [statusFilter]);

  React.useEffect(() => {
    let timerId;
    if (success && counter) {
      const timerId = setTimeout(() => {
        if (counter === 1) {
          resetState();
          clearTimeout(timerId);
        } else {
          setCounter((counter) => counter - 1);
        }
      }, 1000);
    }

    return () => {
      clearTimeout(timerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, counter]);

  const onRepayLoan = (e) => {
    e.preventDefault();
    const validationErrors = validate2(values);
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every((x) => x === "");
    if (!isNoError) {
      return;
    }
    const data = {
      pin: values.pin,
      amount: values.amount,
      accountID: values.accountID,
    };
    setLoading(true);
    postMethod("/api/Transactions/LoanRepayment", data, "post")
      .then((res) => {
        setLoading(false);
        if (res.data) {
          if (res.data.responseCode === "200") {
            notifySuccess(res.data.responseMessage || "Repayment Success");
            setSuccess(true);
          } else {
            let errorMsg =
              res.data?.responseMessage ||
              "Something went wrong!, try again later";
            notifyFailed(errorMsg);
          }
        } else {
          let errorMsg = "Something went wrong!, try again later";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        let errorMsg =
          err?.response?.data?.responseMessage || "Internal Server Error";
        notifyFailed(errorMsg);
        setLoading(false);
      });
  };

  const fetchTrans = async () => {
    let errorMsg = "";
    if (!statusFilter) {
      return;
    }
    setLoading(true);

    let url =
      "/api/Transactions/GetLoanRepaymentSchedule?LoanId=" + statusFilter;

    getMethod(url, "get")
      .then((res) => {
        setLoading(false);
        if (res && res.data) {
          if (res.data.responseCode === "200" && res.data.result) {
            // Success
            setLoanAccount(res.data?.result?.loan_repayment_schedule);
          } else if (res.data.responseCode === "400") {
            setLoanAccount(null);

            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
          } else {
            setLoanAccount(null);

            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          setLoanAccount(null);
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        notifyFailed("Internal Server Error");
      });
  };

  const validate2 = (inputs) => {
    var errors = {};
    if (!inputs?.accountID?.trim().length) {
      errors.accountID = "Account Number is required";
    }
    if (!inputs.amount) {
      errors.amount = "Amount is required";
    }
    if (!inputs.pin || inputs.pin.length < 4) {
      errors.pin = "Pin is required";
    }
    return errors;
  };

  const handleInputChange = (ev) => {
    var { name, value } = ev.target;
    value = value.trim();
    if (name === "amount") {
      if (value === "") {
        setValues({ ...values, [name]: value });
      } else {
        if (checkNumberOnly(value)) {
          setValues({ ...values, [name]: value });
        } else {
          setValues({ ...values, [name]: "" });
        }
      }
    } else if (name === "password") {
      if (pinValidation(value)) {
        setValues({ ...values, [name]: value });
      }
    } else if (name === "otp") {
      if (checkOTP(value)) {
        setValues({ ...values, [name]: value });
      }
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  const checkOTP = (value) => {
    let digiRegex = /^[0-9]*$/;
    if (digiRegex.test(value)) {
      if (value.length <= 6) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const fetchLoanInfo = () => {
    fetchTrans();
  };

  const pinValidation = (value) => {
    let digiRegex = /^[0-9]*$/;
    if (value.length <= 4) {
      if (digiRegex.test(value)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const checkNumberOnly = (value) => {
    // let digiRegex = /^[0-9]*$/;
    let digiRegex = /^[+-]?([1-9]+\.?[0-9]*|\.[0-9]+)$/;
    if (digiRegex.test(value)) {
      return true;
    } else {
      return false;
    }
  };

  const resetState = () => {
    setLoading(false);
    setLoanAccount(null);
    setValues(initialValue);
    setStatusFilter("");
    setSuccess(false);
    setCounter(5);
  };

  const changeStatus = (val) => {
    const value = val;
    if (!value) {
      resetState();
    }
    setStatusFilter(value);
  };

  return (
    <>
      <Header />
      <Container className="mt--5 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="6">
            <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-white border-0">
                <Row Row className="align-items-center">
                  <Col lg="12">
                    <h3 className="mb-0 text-center">Loan Repayment</h3>
                  </Col>
                </Row>
              </CardHeader>

              <CardBody>
                {success ? (
                  <div className="d-flex justify-content-center align-items-center flex-column py-12">
                    <img
                      alt="..."
                      height="140"
                      width="140"
                      className="round-circle"
                      src={
                        require("../../assets/img/brand/success-2.png").default
                      }
                    />
                    <h4>Loan Repayment Success</h4>
                    <p className="p-0 m-0">
                      Loan Id: <b>{values.accountID}</b>
                    </p>
                    <p>
                      Amount: <b>Le. {values.amount}</b>
                    </p>
                    <small>Redirects to home in {counter}</small>
                  </div>
                ) : (
                  <>
                    {/* <div className="border-0 w-100 d-flex justify-content-between align-items-center">
                      <Form
                        className="w-100"
                        onSubmit={(e) => {
                          e.preventDefault();
                          fetchLoanInfo();
                        }}
                      >
                        <FormGroup className="w-100">
                          <label
                            htmlFor="status"
                            className="form-control-label"
                          >
                            Loan Account Id
                          </label>
                          &nbsp; &nbsp;
                          <div className="d-flex w-100" style={{ gap: "12px" }}>
                            <Input
                              type="select"
                              id="status"
                              className="form-control-alternative status-filter"
                              name="statusFilter"
                              value={statusFilter}
                              onChange={(e) => changeStatus(e.target.value)}
                            >
                              {STATUS.map((c) => (
                                <option key={c.key} value={c.key}>
                                  {c.value}
                                </option>
                              ))}
                            </Input>
                            <Input
                              type="text"
                              id="status"
                              placeholder="Loan Account Id"
                              className="form-control-alternative w-100"
                              name="accountID"
                              value={values.accountID}
                              required
                              onChange={handleInputChange}
                            />
                            {errors?.accountID && (
                              <div className="text-left text-danger">
                                <small>* {errors?.accountID}</small>
                              </div>
                            )}
                            {/* <Button type="submit" color="primary">
                              Submit
                            </Button> */}
                    {/* </div>
                        </FormGroup>
                      </Form>
                    </div> */}
                    {/* {loanAccount && (
                      <div className="mb-4">
                        <h4>Loan Account Details</h4>
                        <Table
                          className="align-items-center table-flush"
                          responsive
                        >
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">account Status</th>
                              <th scope="col">product ID</th>
                              <th scope="col">loan Amount</th>
                              <th scope="col">interest Rate</th>
                              <th scope="col">numberOf Installments</th>
                              <th scope="col">approved On</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{loanAccount?.accountStatus}</td>
                              <td>{loanAccount?.productID}</td>
                              <td>{loanAccount?.loanAmount}</td>
                              <td>{loanAccount?.interestRate}</td>
                              <td>{loanAccount?.numberOfInstalments}</td>
                              <td>
                                {moment(loanAccount?.approvedOn).format("lll")}
                              </td>{" "}
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    )}
                    {loanAccount && ( */}
                    <div>
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-accountID"
                            >
                              Account ID
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-accountID"
                              placeholder="Enter Account Id"
                              type="text"
                              name="accountID"
                              max={30}
                              value={values.accountID}
                              onChange={handleInputChange}
                              disabled={loading}
                            />
                            {errors.accountID && (
                              <div className="text-left text-danger">
                                <small>* {errors.accountID}</small>
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-amount"
                            >
                              Amount
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-amount"
                              placeholder="Enter Amount"
                              type="text"
                              name="amount"
                              max={loanAccount?.loanAmount}
                              value={values.amount}
                              onChange={handleInputChange}
                              disabled={loading}
                            />
                            {errors.amount && (
                              <div className="text-left text-danger">
                                <small>* {errors.amount}</small>
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        {/* <Col>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-pswd"
                            >
                              PIN
                            </label>

                            <InputGroup className="input-group-alternative">
                              <Input
                                className="form-control-alternative"
                                id="input-pswd"
                                type={showPswd ? "text" : "password"}
                                name="pin"
                                value={values.pin}
                                placeholder="Enter 4-digit PIN"
                                onChange={handleInputChange}
                                disabled={loading}
                              />
                              {!showPswd && (
                                <InputGroupAddon addonType="append">
                                  <InputGroupText>
                                    <i
                                      className="fa fa-eye"
                                      onClick={() => setShowPswd(true)}
                                    />
                                  </InputGroupText>
                                </InputGroupAddon>
                              )}

                              {showPswd && (
                                <InputGroupAddon addonType="append">
                                  <InputGroupText>
                                    <i
                                      className="fa fa-eye-slash"
                                      onClick={() => setShowPswd(false)}
                                    />
                                  </InputGroupText>
                                </InputGroupAddon>
                              )}
                            </InputGroup>
                            
                            {errors.pin && (
                              <div className="text-left text-danger">
                                <small>* {errors.pin}</small>
                              </div>
                            )}
                          </FormGroup>
                        </Col> */}
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-pin"
                            >
                              PIN
                            </label>
                            <OTPInput
                              value={values.pin}
                              onChange={(e) => setValues({ ...values, pin: e })}
                              inputStyle={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "12px",
                                border: "1px solid #ddd",
                                marginRight: "8px",
                              }}
                              inputType="password"
                              numInputs={4}
                              renderSeparator={<span> </span>}
                              renderInput={(props) => <input {...props} />}
                            />
                            {errors.pin && (
                              <div className="text-left text-danger">
                                <small>* {errors.pin}</small>
                              </div>
                            )}{" "}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button
                            className="btn float-right"
                            color="primary"
                            type="submit"
                            disabled={loading}
                            onClick={onRepayLoan}
                          >
                            Submit{" "}
                            {loading && <i class="fa fa-spinner fa-spin"></i>}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                    {/* )} */}
                    {/* {loading && <FullPageLoader label={"Fetching..."} />} */}
                    {/* {!loading && statusFilter && !loanAccount && (
                      <div className="text-center">No records found</div>
                    )} */}
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LoanRepayment;
