
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Table,
  Badge
} from "reactstrap";
// core components

import { useToasts } from 'react-toast-notifications';
import Header from "components/Headers/Header";
import FullPageLoader from "components/FullPageLoader/fullpageloader";
import { collBankStatus } from "appConfig";
import { getMethod } from "services/httpServices";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const initialValue = [
  { name: "Keanu Reeves", profession: "Actor" },
  { name: "Lionel Messi", profession: "Football Player" },
  { name: "Cristiano Ronaldo", profession: "Football Player" },
  { name: "Jack Nicklaus", profession: "Golf Player" },
]

const STATUS = collBankStatus;


const SearchTransaction = (props) => {

  const [values, setValues] = React.useState(initialValue);
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const [error, setError] = React.useState(null);
  const [response, setResponse] = React.useState({});

  const { addToast } = useToasts();

  const [people, setPeople] = React.useState(initialValue);

  const notifyFailed = (text) => {
    if (!text) {
      text = 'Error in saving';
    }
    addToast(text, {
      appearance: 'error',
      autoDismiss: true,
    });
  }

  const notifySuccess = (text) => {
    if (!text) {
      text = 'Save successfully';
    }
    addToast(text, {
      appearance: 'success',
      autoDismiss: true,
    });
  }

  const submit = () => {
    if (!searchText) {
      setError('Please Enter Transaction ID');
      setLoading(false);
      setResponse({});
    } else {
      setError(null);
      setLoading(true);
      setResponse({});
      fetchTrans(searchText);
    }
  }

  const statusFormat = (val) => {
    let ob = STATUS.find((s) => s.key === val);
    // return ob && ob.value ? ob.value : val;
    return ob && ob.value ? ob.value : val
  };

  const fetchTrans = async (id) => {
    let errorMsg = '';
    getMethod('/api/Reports/TrackTransaction/' + id, 'get')
    .then(res => {
      setLoading(false);
      if (
        res && res.data
      ) {
        if (res.data.responseCode === "200") {
          // Success
          if (
            !res.data.result
          ) {
            setError('No transaction found');
          } else {
            setResponse(res.data.result);
          }
        } else if (res.data.responseCode === "400") {
          // Error
          errorMsg = res.data.responseMessage || 'Error in fetching list';
          setError(errorMsg);
        } else {
          // Unknown Error
          errorMsg = 'Unknown Error in fetching';
          setError(errorMsg);

        }
      } else {
        errorMsg = 'Unknown Error';
        setError(errorMsg);

      }
    }).catch(err => {
      setLoading(false);
      setError(errorMsg);    })
  }

  const getAmt = (amount) => {
    let amt = Number(amount);
    return amt.toLocaleString();
  }

  const reset = () => {
    setLoading(false);
    setResponse({});
    setSearchText('');
  }
  const txtText1 = response?.transactionType === "BalanceTransfer" ? "Transfer From" : "Withdrawn From";
  const txtText2 = response?.transactionType === "BalanceTransfer" ? "Transferred to" : "Deposited To";
  
  return (
    <>
      {/* Page content */}
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row className="justify-content-center">
          <Col xl="8">
            <Card className="bg-secondary shadow">
              <CardHeader
                className="border-0 d-flex justify-content-center card-header-border">
                <Form className="form-inline mr-3 d-md-flex ml-lg-auto mt-4 mb-2"
                  onSubmit={e => { e.preventDefault(); }}>

                  <FormGroup className="mb-0 navbar-search ">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input className="search-cls" placeholder="Enter Transaction ID"
                        type="text"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  &nbsp; &nbsp;
                  <Button color="primary"
                    onClick={reset}
                    disabled={loading}>
                    Reset
                  </Button>

                  <Button color="primary"
                    onClick={submit}
                    disabled={loading}>
                    Submit
                  </Button>
                </Form>
              </CardHeader>
              <CardBody className="search-trans-cls">

                {loading && <FullPageLoader />}

                {error &&
                  <div className="text-center text-warning">
                    {error}
                  </div>}

                {response && response.transactionId &&
                  <div>
                    <Row className="mb-4">
                      <Col lg="12" className="text-center">
                        <strong>Transaction Details</strong>
                      </Col>
                    </Row>
                    <div className="center-div">
                      <div className="mt-5 ml-5">
                        <Row>
                          <Col lg="5">
                            <small>
                              <strong>
                                Transaction ID : 
                              </strong>
                            </small>
                          </Col>
                          <Col lg="7">
                            <small>
                              {response.transactionId}
                            </small>
                          </Col>
                        </Row>
                    
                        <Row>
                          <Col lg="5">
                            <small>
                              <strong>{txtText1} : </strong>
                            </small>
                          </Col>
                          <Col lg="7">
                            <small>{response.transationFrom}</small>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="5">
                            <small>
                              <strong>{txtText2} :</strong>
                            </small>
                          </Col>
                          <Col lg="7">
                            <small>{response.transationTo}</small>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="5">
                            <small>
                              <strong>Amount :</strong>
                            </small>
                          </Col>
                          <Col lg="7">
                            <small>
                              Le {getAmt(response.amount)}
                            </small>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="5">
                            <small>
                              <strong>Smart Pay Fees :</strong>
                            </small>
                          </Col>
                          <Col lg="7">
                            <small>
                              Le {getAmt(response.fees)}
                            </small>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="5">
                            <small>
                              <strong>Status :</strong>
                            </small>
                          </Col>
                          <Col lg="7">
                            <small>
                              {statusFormat(response.currentStatus)}
                            </small>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="5">
                            <small>
                              <strong>Description :</strong>
                            </small>
                          </Col>
                          <Col lg="7">
                            <small>{response.description}</small>
                          </Col>
                        </Row>

                      </div>
                    </div>

                  </div>

                }

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SearchTransaction;
