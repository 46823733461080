import React, { useState } from "react";
import {
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Modal,
  ModalBody
} from "reactstrap";
import { setUserDetails, getMobCodes, checkDigitOnly, checkBankAccNo, checkBbanValidation } from "services/util";
import { checkSpace } from '../../services/httpServices';
import { AUTH_MODE, AMOUNT_HINT, MOBILE_ONLY_COUNTRY } from 'appConfig';
import PhoneInput from "react-phone-input-2";
import PreviewSubscriber from '../preview-subscriber';
import "react-phone-input-2/lib/style.css";
import { useHistory } from "react-router-dom";

const BankDetails = (props) => {
  const BANKS = props.banks ? props.banks : [];
  const codes = getMobCodes();
  const initialValue = setUserDetails(props);
  const [banks, setBanks] = useState(BANKS);
  const [values, setValues] = useState(initialValue);
  const [errors, setErrors] = React.useState({});
  const [open, setOpen] = React.useState(false);

  const history = useHistory();

  const MODES = AUTH_MODE;
  const HINT = AMOUNT_HINT;

  const handleInputChange = (e) => {
    var { name, value } = e.target;
    // value = value.trim();
    if (checkSpace(value)) {
      if (name === "bankLinkedPhone") {
        if (checkMobValidation(value)) {
          setValues({ ...values, [name]: value });
        }
      } else if (name === 'bban') {
        if (checkBbanValidation(value)) {
          setValues({ ...values, [name]: value });
        }
      } else if (name === 'bankAccNo') {
        // if (checkBankAccNo(value)) {
          setValues({ ...values, [name]: value });
        // }
      } else {
        setValues({ ...values, [name]: value });
      }
    }
  };

  const phoneChange = (value, data, event, formattedValue) => {
    if (data.dialCode && data.dialCode.length) {
      setValues({...values, 
        bankLinkedPhone: value, 
        bankLinkedMobileCode: data.dialCode,
        bankMobWithoutCC: value.substring(data.dialCode.length)
      });
    }
    return;
  }

  const checkMobValidation = (value) => {
    let digiRegex = /^[0-9]*$/;
    if (value.length <= 6) {
      if (digiRegex.test(value)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  const goBack = () => {
    props.goBack();
  }

  const validateBank = (e) => {
    e.preventDefault();
    if (checkBank()) {
      props.parentCallback(values);
      setOpen(true);
    }
  }

  const checkBank = () => {
    let error = {};
    let valid = true;
    if (!values.bank) {
      error.bank = 'Bank is Required';
      valid = false;
    }
    // if (!values.bban) {
    //   error.bban = 'Bban Number is Required';
    //   valid = false;
    // } else if (values.bban.length < 8 || values.bban.length > 18) {
    //   error.bban = 'Please enter 8 to 18 digit BBAN Number';
    //   valid = false;
    // }
    // if (values.bban.length !== 0 && (values.bban.length < 8 || values.bban.length > 18)) {
    //     error.bban = 'Please enter 8 to 18 digit BBAN Number';
    //     valid = false;
    //   }

    if (!values.bankLinkedMobileCode) {
      error.bankLinkedMobileCode = 'Mobile Code is Required';
      valid = false;
    }
    // if (!values.bankLinkedPhone) {
    //   error.bankLinkedPhone = 'Phone number is Required';
    //   valid = false;
    // } else if (!values.bankMobWithoutCC) {
    //   error.bankLinkedPhone = 'Phone number is Required';
    //   valid = false;
    // } 

    if (!values.authMode) {
      error.authMode = 'Please select authentication mode';
      valid = false;
    }

    if (values.authMode == "1FA" && values.oneFAAmt == '') {
      error.oneFAAmt = "Please enter amount";
      valid = false;
    }

    if (!values.bankAccNo) {
      error.bankAccNo = 'Please enter account number';
      valid = false;
    } 
    // else if (values.bankAccNo.length < 8 || values.bankAccNo.length > 18) {
    //   error.bankAccNo = 'Please enter 8 to 18 digit Bank Account Number';
    //   valid = false;
    // }
    setErrors(error);
    return valid;
  }

  const closeModal = () => {
    setOpen(false);
    // history.push('/auth/login');
    history.push({
      pathname: '/show-msg',
      state: {
        error: false, 
        msg: `You've been successfully registered on APEX`,
        login: true
      }
    });
  }

  const reviewModal = () => {
    setOpen(false);
  }

  return (
    <>
       <Modal
          isOpen={open}
          style={{ width: "70vw", maxWidth: "70vw" }}
      >
        <ModalBody style={{ padding: 0 }}>
          <PreviewSubscriber
            closeModal={closeModal}
            reviewModal={reviewModal}
            {...values}
            bankList={props.banks}
            mobileMoneyList={props.mmo}
            selfRegistered={true}
          />
        </ModalBody>
      </Modal>
      <Form onSubmit={validateBank} autoComplete="off">
        <Row>
          <Col lg="12">
            <FormGroup>
              <label
                for="input-bank"
                className="form-control-label text-white">
                Bank
              </label>
              <Input
                type="select"
                id="input-bank"
                className="form-control-alternative"
                name="bank"
                value={values.bank}
                onChange={handleInputChange}
              >
                <option key="" value="">----Select Bank-----</option>
                {banks && banks.map(c => <option key={c.key} value={c.key}>{c.value}</option>)}
              </Input>
              {errors.bank && <div className="text-left text-danger">
                <small>* {errors.bank}</small>
              </div>}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          {/* <Col lg="12">
            <FormGroup>
              <label
                className="form-control-label text-white"
                htmlFor="input-bban"
              >
                BBAN Number (optional)
              </label>
              <Input
                className="form-control-alternative"
                id="input-bban"
                placeholder="BBAN Number (optional)"
                type="text"
                name="bban"
                value={values.bban}
                onChange={handleInputChange}
              />
              {errors.bban && <div className="text-left text-danger">
                <small>* {errors.bban}</small>
              </div>}
            </FormGroup>
          </Col> */}

        </Row>

        <Row>
          <Col lg="12">
            <FormGroup>
              <label
                className="form-control-label text-white"
                htmlFor="input-bank-accno"
              >
                Bank Account Number
              </label>
              <Input
                className="form-control-alternative"
                id="input-bank-accno"
                placeholder="Bank Account Number"
                type="text"
                name="bankAccNo"
                value={values.bankAccNo}
                onChange={handleInputChange}
              />
              {errors.bankAccNo && <div className="text-left text-danger">
                <small>* {errors.bankAccNo}</small>
              </div>}
            </FormGroup>
          </Col>
        </Row>

        {/* <Row>
          <Col>
            <FormGroup>
              <label
                className="form-control-label text-white"
                htmlFor="input-phone"
              >
                Phone Number
              </label>
              <PhoneInput
                className="form-control-alternative"
                country={'sl'}
                name="bankLinkedPhone"
                value={values.bankLinkedPhone}
                onlyCountries={MOBILE_ONLY_COUNTRY}
                countryCodeEditable={false}
                onChange={phoneChange}
              />
              {errors.bankLinkedPhone && <div className="text-left text-danger">
                <small>* {errors.bankLinkedPhone}</small>
              </div>}
            </FormGroup>
          </Col>
        </Row> */}

        <Row>

          <Col lg="6">
            <FormGroup>
              <label
                for="input-authmode"
                className="form-control-label text-white">
                Authentication Mode
              </label>
              <Input
                type="text"
                id="input-authmode"
                className="form-control-alternative"
                name="authMode"
                value={values.authMode}
                onChange={handleInputChange}
                disabled
              />
                {/* <option key="">----Select Mode-----</option>
                {MODES.map(c => <option key={c}>{c.value}</option>)}
              </Input> */}
              {errors.authMode && <div className="text-left text-danger">
                <small>* {errors.authMode}</small>
              </div>}
            </FormGroup>
          </Col>

          {values.authMode == "1FA" && <Col lg="6">
            <FormGroup>
              <label
                className="form-control-label text-white"
                htmlFor="input-1FA"
              >
                Enter amount for using 1FA
              </label>
              <Input
                className="form-control-alternative"
                id="input-1FA"
                placeholder="Amount"
                type="text"
                name="oneFAAmt"
                value={values.oneFAAmt}
                onChange={handleInputChange}
                disabled
              />

              <div className="text-left">
                <i>
                  <small>* {HINT}</small>
                </i>
              </div>
              {errors.oneFAAmt && <div className="text-left text-danger">
                <small>* {errors.oneFAAmt}</small>
              </div>}

            </FormGroup>
          </Col>}

        </Row>
        <Row>
          <Col lg-6>
            <div>
              <Button className="sign-up-btn float-left" color="primary"
                onClick={goBack}>
                <i class="fas fa-arrow-alt-circle-left" style={{ fontSize: '18px' }}></i> Back
              </Button>
            </div>
          </Col>
          <Col lg-6>
            <div>
              <Button className="sign-up-btn float-right"
                color="primary" type="submit">
                Save and Preview
              </Button>
            </div>
          </Col>
        </Row>

      </Form>
    </>
  );
}

export default BankDetails;